import { render, staticRenderFns } from "./DashDetail.vue?vue&type=template&id=3fca2318&scoped=true&"
import script from "./DashDetail.vue?vue&type=script&lang=js&"
export * from "./DashDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fca2318",
  null
  
)

export default component.exports