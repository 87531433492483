<template>
    <div>
        <b-card no-body class="p-1 px-2">
            <!-- User Info: Top col -->
            <div class="d-flex flex-wrap align-items-center justify-content-between">
                <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap">
                    Action List - Client Account
                </h4>
            </div>
            <hr />
            <div class="">
                <b-tabs content-class="mt-2" justified>
                    <b-tab v-for="(titles, index) in clientData" :title="titles.name" :key="`title_${index}`">
                        <b-table v-if="titles.type === 'pi'" striped hover :items="titles.data" :fields="clientPIfields" show-empty responsive>
                            <template #cell(employer_company_id)="data">
                                <div v-if="data.item.employer_company_id == 1">JVSakk Asia Limited</div>
                                <div v-if="data.item.employer_company_id == 2">JVSakk Asset Management Limited</div>
                            </template>
                        </b-table>
                        <b-table v-else-if="titles.type === 'standing'" striped hover :items="titles.data" :fields="clientStandingfields" show-empty responsive>
                            <template #cell(employer_company_id)="data">
                                <div v-if="data.item.employer_company_id == 1">JVSakk Asia Limited</div>
                                <div v-if="data.item.employer_company_id == 2">JVSakk Asset Management Limited</div>
                            </template>
                        </b-table>
                        <b-table v-else-if="titles.type === 'discretionary'" striped hover :items="titles.data" :fields="clientDiscretionaryfields" show-empty responsive>
                            <template #cell(employer_company_id)="data">
                                <div v-if="data.item.employer_company_id == 1">JVSakk Asia Limited</div>
                                <div v-if="data.item.employer_company_id == 2">JVSakk Asset Management Limited</div>
                            </template>
                        </b-table>
                        <b-table v-else-if="titles.type === 'fatca'" striped hover :items="titles.data" :fields="clientFATCAfields" show-empty responsive>
                            <template #cell(employer_company_id)="data">
                                <div v-if="data.item.employer_company_id == 1">JVSakk Asia Limited</div>
                                <div v-if="data.item.employer_company_id == 2">JVSakk Asset Management Limited</div>
                            </template>
                        </b-table>
                    </b-tab>
                </b-tabs>
            </div>
            <!-- User Table: Left col -->
            <b-row class="">
                <b-col class="d-flex flex-wrap" style="padding-top: 15px;">
                </b-col>
            </b-row>

        </b-card>
        <b-card no-body class="p-1 px-2">
            <!-- User Info: Top col -->
            <div class="d-flex flex-wrap align-items-center justify-content-between">
                <h4 class="mb-0 ml-0 color4 font-weight-bolder text-nowrap">
                    Action List - User
                </h4>
            </div>
            <hr />
            <div class="">
                <b-tabs content-class="mt-2" justified>
                    <b-tab v-for="(titles, index) in staffData" :title="titles.name" :key="`title2_${index}`">
                        <b-table v-if="titles.name == 'Client Birthday'" striped hover :items="titles.data" :fields="stafffieldsClient" show-empty responsive>
                            <template #cell(employer_company_id)="data">
                                <div v-if="data.item.employer_company_id == 1">JVSakk Asia Limited</div>
                                <div v-if="data.item.employer_company_id == 2">JVSakk Asset Management Limited</div>
                            </template>
                            <template #cell(client_name)="data">
                                <div>({{ data.item.namename }}) - {{data.item.client_name}}</div>
                            </template>
                        </b-table>
                        <b-table v-else striped hover :items="titles.data" :fields="stafffields" show-empty responsive>
                            <template #cell(employer_company_id)="data">
                                <div v-if="data.item.employer_company_id == 1">JVSakk Asia Limited</div>
                                <div v-if="data.item.employer_company_id == 2">JVSakk Asset Management Limited</div>
                            </template></b-table>
                    </b-tab>
                </b-tabs>
            </div>
            <!-- User Table: Left col -->
            <b-row class="">
                <b-col class="d-flex flex-wrap" style="padding-top: 15px;">
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import {
    BCard, BButton, BAvatar, BRow, BCol, BBadge,
    BCardText, BTab, BTabs, BTable, VBToggle, BForm, BFormGroup, BPagination, BDropdown, BDropdownItem, BSidebar,
    BFormInvalidFeedback, BOverlay, BFormInput, BModal, VBModal, BFormFile
} from "bootstrap-vue";
import Ripple from 'vue-ripple-directive'
import flatPickr from "vue-flatpickr-component";
import 'flatpickr/dist/flatpickr.css';
import { avatarText } from "@core/utils/filter";
import { ref, onMounted } from "@vue/composition-api";
import store from "@/store";
export default {
    components: {
        BCard,
        BButton,
        BRow,
        BCol,
        BAvatar,
        BBadge,
        BCardText,
        BTab,
        BTabs,
        BTable,
        BForm,
        BFormGroup,
        BPagination,
        BDropdown,
        BDropdownItem,
        BSidebar,
        BOverlay,
        BFormInput,
        BButton,
        BModal,
        BFormInvalidFeedback,
        flatPickr,
    },
    directives: {
        'b-toggle': VBToggle,
        'b-modal': VBModal,
        Ripple,
    },
    data() {
        return {
            selected: '',
            clientData: '',
            staffData: '',
            // clientfields: ['id', { key: 'name_en', label: 'Name' }, 'email', 'pi_expiration_date', 'authority_expiration_date', 'fatca_expiration_date', 'employer_company_id', 'contact_number'],
            clientPIfields: ['id', { key: 'name_en', label: 'Name' }, 'email', 'contact_number', 'employer_company_id', { key: 'pi_expiration_date', label: 'PI Expiration Date' }],
            clientStandingfields: ['id', { key: 'name_en', label: 'Name' }, 'email', 'contact_number', 'employer_company_id', { key: 'authority_expiration_date', label: 'Standing Authority Expiration Date' }],
            clientDiscretionaryfields: ['id', { key: 'name_en', label: 'Name' }, 'email', 'contact_number', 'employer_company_id', { key: 'authority_expiration_date', label: 'Discretionary Authority Expiration Date' }],
            clientFATCAfields: ['id', { key: 'name_en', label: 'Name' }, 'email', 'contact_number', 'employer_company_id', { key: 'fatca_expiration_date', label: 'FATCA Expiration Date' }],
            stafffields: ['id', { key: 'name', label: 'RM' }, 'email', 'contact_number', 'employer_company_id', 'contact_number'],
            stafffieldsClient: ['id', 'client_name', { key: 'name', label: 'RM' }, 'email', 'contact_number', 'employer_company_id', 'contact_number'],
        }
    },
    methods: {
        fetchDashboardToDo() {
            store
                .dispatch("app/fetchDashboardToDo")
                .then((response) => {
                    console.log(response);
                    this.clientData = response.data.summary.slice(0, 4);
                    this.staffData = response.data.summary.slice(4);
                })
                .catch((error) => {
                    console.log(error);
                })
        },
    },
    created() {
        this.fetchDashboardToDo();
    },
}
</script>

<style lang="scss" scoped></style>